import React from "react";

import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import cx from "classnames";

import { useUser } from "../hooks/UserProvider";
import Page from "../components/Page";
import useSWR from "swr";

export default function Home() {
  const { username, changeUsername, userId } = useUser();
  const router = useRouter();
  const { g, l } = router.query;
  const { data } = useSWR(() => "/api/pusher/info?q=presence-" + g, {
    refreshInterval: 4000,
  });
  const members = data?.map((d) => d.id.split("|")[1]) || [];

  return (
    <Page className="flex justify-center items-center">
      {l === "1" && (
        <div className="bg-red-300 border border-red-500 text-white text-xs absolute top-4 px-4 py-2 font-mono">
          The game you tried to join is already full.
        </div>
      )}
      <div className="flex flex-col text-center">
        {members.length > 0 && (
          <>
            <div className="p-2 flex flex-row justify-center items-center">
              {members.map((n, i) => (
                <div key={n} className={cx("inline-block", { "ml-5": i > 0 })}>
                  <Image
                    className="inline-block rounded-full border"
                    src={`https://robohash.org/${n}.png?set=set4&size=40x40`}
                    width={40}
                    height={40}
                    alt={`user logo for ${n}`}
                  />
                  <span className="inline-block font-mono text-sm ml-2">
                    {n}
                  </span>
                </div>
              ))}
            </div>
            <hr className="my-8" />
          </>
        )}
        <form
          className="block"
          onSubmit={async (e) => {
            e.preventDefault();

            if (g) {
              return router.push(`/${g}`);
            }

            const response = await fetch("/api/game", {
              headers: { userId },
            });

            if (response.ok) {
              const data = await response.json();

              router.push(data.id);
            }
          }}
        >
          <label className="block mb-4 font-mono text-base cursor-pointer">
            <span className="text-xs">
              <Image
                className="inline-block border-black rounded-md mb-3"
                src={`https://robohash.org/${
                  username || "dicebase"
                }.png?set=set4&size=80x80`}
                width={80}
                height={80}
                alt={`user logo for ${username || "dicebase"}`}
              />
            </span>
            <input
              className="block py-2 text-center border-2 focus:border-blue-600 outline-none w-full"
              type="text"
              placeholder="Choose username"
              value={username}
              onChange={(e) => changeUsername(e.target.value)}
              onBlur={(e) => {
                changeUsername(e.target.value);
              }}
            />
          </label>
          <button className="py-3 px-5 inline-block w-full bg-black text-white uppercase tracking-wider hover:shadow-md">
            {g ? "Join game" : "Create game"}
          </button>
        </form>
        <Link href="/game" passHref>
          <button
            id="solo"
            className="mt-2 py-3 px-5 inline-block bg-black text-white uppercase tracking-wider hover:shadow-md"
          >
            {"Solo game"}
          </button>
        </Link>
      </div>
    </Page>
  );
}
