import React from "react";

import Link from "next/link";
import cx from "classnames";

export default function Page({ children, className, headerComponents }) {
  return (
    <div className={cx("min-h-screen w-screen pt-12", className)}>
      <header className="fixed top-0 w-screen h-12 bg-black text-white flex flex-row items-center justify-between z-10">
        <Link href="/">
          <a className="font-mono ml-4">dicebase</a>
        </Link>
        {headerComponents}
      </header>
      {children}
    </div>
  );
}
